import  { useEffect, useState } from 'react' 
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import moment from 'moment'; 
import InwardContainerHeader from './InwardContainerHeader';
import InwardDeliverySheet from '../../services/inwarddeliverysheet.service';
import Inwarddetailsercice from '../../services/inwarddetail.service';

function InwardEmailIntimation() {


  const [searchQuery, setSearchQuery] = useState(''); 
  const [selectedRecordList, setselectedRecordList] = useState([])
  const [inwardDate, setinwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [allInwardList, setallInwardList] = useState([]); 
 
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;    
    onSelectAll :any
  }

  const selectRow: selectRowType = {
    mode: "checkbox",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row :never, isSelect :any, rowIndex :any, e:any) => {
        
        // console.log("row", row['employeeTableId'])
        if(isSelect){
            setselectedRecordList([...selectedRecordList, row])
        }else {
            const secondIndex = selectedRecordList.findIndex((Selectrow) => Selectrow['id'] === row['id']);
            selectedRecordList.splice(secondIndex, 1);
        }
      },
      onSelectAll: async (isSelect :any, rows :any, e :any) => {
       if(isSelect){
        var IdArray :[] = [];
        rows.map(async (rowDatas:never)=>{
            IdArray.push(rowDatas) ;
        })
        await setselectedRecordList(IdArray)
       }else {
       await setselectedRecordList([])
       }
      },
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
  const dateformater = (data: any, row: any) => {
    return  (moment(data)).format('DD/MM/YYYY')
  }
  const timeformater = (data :any, row: any) => {
    if(data.length <= 8) {
      return (data)
    }else {
    const parsedDate = moment(data, "YYYY-MM-DD@HH:mm:ss");
    return (parsedDate.format('hh:mm A'))
    }
  }
  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'id',  align: 'center',sort: true },
    { dataField: 'inwardDate', text: 'Inward Date', sort: true },
    { dataField: 'inwardTime', text: 'Inward Time', sort: true ,  formatter :timeformater },
    { dataField: 'shipmentModeName', text: 'Mode', align:'left'  },
    { dataField: 'AWB', text: 'AWB No', align:'left'  },
    { dataField: 'consignerName', text: 'Consignor', align:'left' },    
    { dataField: 'employeeName', text: 'Employee', align:'left'  },
    { dataField: 'departmentName', text: 'Department', align:'left'  },
    { dataField: 'inwardQuantity', text: 'Pcs', align:'center' },
    { dataField: 'remark', text: 'Remark' },
    { dataField: 'inwarddetailStatusDescription', text: 'status' , align:'left' },
    { dataField: 'mailIntimation', text: 'Mail Sent' , formatter: (cell, row, rowIndex, formatExtraData) => {
      const color = cell === 1 ? 'green' : 'red';
      return (
        <span style={{ color }}>
          {cell === 1 ? 'Yes' : 'No'}
        </span>
      );
    }}


  ];
  //Table End
  const getInwardDetailList = async () => {
    // setallInwardList([])
    await Inwarddetailsercice.getInwardDetailsPendigtoDeliveyList(inwardDate, checkpendingfilter).then(
      (data) => {
        console.log("data", data)
        if (data) {
            setallInwardList(data);
            // data.map(async (inwards:never)=>{
            //     // console.log("inwards", inwards['receivedBy'])
            // if(inwards['receivedBy'] ===  null){
            //     // console.log(inwards)
            //     allInwardList.push(inwards)
            // //   await  setallInwardList([...allInwardList, inwards]);
            // }
            // })
        //   console.log("allInwardList", allInwardList)
        } 
      }
    );
  }
  const [checkpendingfilter, setCheckPendingFilter] = useState(false)
  const filteronlyPending = () => {
    setCheckPendingFilter(!checkpendingfilter);  
  } 
  useEffect(() => {
    getInwardDetailList();
  }, [inwardDate, checkpendingfilter])
  useEffect(() => {
    setinwardDate((moment(new Date())).format('YYYY-MM-DD'))
  }, [])

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const handleSendMail = () => {
const uniqueTags:any = [];
selectedRecordList.map(selc => {
    if (uniqueTags.indexOf(selc['employeeTableId']) === -1) {
        uniqueTags.push(selc['employeeTableId'])
    }
});
const inwardIdsByEmployee :any = [];

uniqueTags.map((empId : any) => {
        const filteredDatainwardId:any = selectedRecordList.filter((inwrd) => inwrd['employeeTableId'] == empId); 
      //  console.log( "filteredDatainwardId['id'],",  filteredDatainwardId)
       const inwrdIds = filteredDatainwardId.map((datss :any) =>{
        return datss.id
       })
        const imps = {employeeId : empId , inwardId : inwrdIds }
        inwardIdsByEmployee.push(imps)
})
if(inwardIdsByEmployee  && inwardIdsByEmployee.length){
      Swal.fire({
        title: 'Are you sure?',
        text: "You wont to Send Email to selected List!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Please Send it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InwardDeliverySheet.sendEmailNotification(inwardIdsByEmployee,checkpendingfilter)
            .catch((error) => {
              console.log(error);
              console.log("server responding");
            })
          Swal.fire({
            title: "E-Mail Sent",
            icon: 'success',
            text: "OK EMail Sent Successfully !",
          }).then(function () {
            window.location.reload()
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "OK EMail not Sent ! :)",
            }).then(function () {
              window.location.reload();
            }
            )
        }
      }) 
    }else {
      Swal.fire({
        position: 'center',
        title: "Data Warning",
        icon: 'warning',
        text: "Please select atlease one record",
      }) 
    }
  }
 
  const filteredData =  Array.isArray(allInwardList)
    ? 
     allInwardList.filter((selectedRecords) => {
      console.log("checkpendingfilter", checkpendingfilter)
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): value is string =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
    : []; 
  return (
    <>
      <InwardContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{ paddingLeft: "0px" }}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Send Inward Detials Email  </p></div>

            <div className='col-12' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "space-evenly", paddingTop: '1%' }}>

              <div className='margincard1itams' style={{ marginLeft: "10px" }}>
                <label> Inward Date </label>
                <input className='margininutleft' type='Date' value={inwardDate} max={moment().format("YYYY-MM-DD")} name="inwardDate" onChange={(e) => { setinwardDate(e.target.value) }} />
              </div>
              <div className='margincard1itams'>
                <label >Branch Name :  {(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branchName : ""} </label>
              </div>
              <div className='col-6' style={{ display: "flex", height: "50px", justifyContent: "end" }}>
                <input
                  className='searchbar col-6'
                  type='text'
                  value={searchQuery}
                  placeholder='Search Here ...'
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="table-responsive tableContainer">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign :'center'}}
              keyField='id'
              striped
              hover
              condensed
              // data={allServiceProvider}
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
</div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
            <div className="col-3"> <input type='checkbox'  checked={checkpendingfilter}  onClick={filteronlyPending} /> Manually Resend Intimations</div>
            <button  className="btn2 col-2" onClick={handleSendMail}  > <i className="fa fa-plus" aria-hidden="true"></i>Send  Mail</button>
            <button className="btn2 col-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
            </div>
        </div>
      </div>
    </>
  );
}

export default InwardEmailIntimation